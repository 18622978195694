<template>
  <div class="mx-6">
    <router-link
      :to="'/bulk/' + data.slug"
      style="text-decoration: none; color: black"

    >
      <div class="py-2">
        <div class="d-flex flex-row justify-start">
          <v-icon class="p-2" color="black" size="20">
            mdi-storefront-outline
          </v-icon>
          <span class="p-2 ms-3">{{ data.store_name }}</span>
        </div>
        <div class="d-flex flex-row justify-start">
          <v-icon class="p-2" color="black" size="20">
            mdi-calendar-month
          </v-icon>
          <span class="bulk-text-header p-2 ms-3">{{
            data.menu_date
          }}</span>
        </div>
      </div>
      <v-card max-width="344">
        <v-img
          :src="data.image_url"
          :lazy-src="data.image_url"
          height="200px"
        ></v-img>
        <v-row class="ms-2 py-4">
          <v-col>
            <span class="bulk-item-text">{{ data.item_name }}</span>
          </v-col>
          <v-col>
            <p
              class="d-flex justify-end text--primary px-2 py-1"
              style="font-size: 14px"
            >
              {{ $t("message.stock") }}: {{ data.stock }}
            </p>
          </v-col>
        </v-row>
        <!-- <div class="d-flex justify-end bulk-text-foote">
          <div class="d-flex justify-end mr-3">
            <span>¥{{ formatDecWithoutComa(data.price) }}</span>
          </div>
        </div> -->
        <!-- <div class="d-flex justify-end bulk-text-footer pb-3" style="color: #ff0090;">
          <span class="pe-2" style="font-size: 75%;">{{ $t("message.label-price-tax") }}</span>
          <div class="d-flex justify-end">
            <span class="pe-1" style="">{{ formatDecWithoutComa(data.price_with_tax) }}</span>
            <span class="" style="font-size: 75%;">円</span>
          </div>
        </div> -->
        <div class="d-flex flex-row justify-end align-end pb-3" style="color: #ff0090;">
          <span style="margin-right: 10px;">
            <span style="font-size:75%;vertical-align: baseline;">
              {{ $t("message.label-price-tax") }}
            </span>
            <span>
              {{formatDecWithoutComa(data.price_with_tax)}}
            </span>
            <span style="font-size:75%;vertical-align: baseline;"> 円</span>
          </span>
        </div>
      </v-card>
    </router-link>
  </div>
</template>

<script>
import { moneyFormat, dateFormat } from "../../helpers";
import priceBeforeComa from '../../helpers/priceBeforeComa'
import priceAfterComa from '../../helpers/priceAfterComa'
import formatDecWithoutComa from '../../helpers/formatDecWithoutComa'

export default {
  name: "ProductBulk",
  date() {
    return {
      total: 0,
    };
  },
  props: {
    data: Object,
  },
  methods: {
    priceBeforeComa(money) {
      return priceBeforeComa(String(money))
    },
    priceAfterComa(money) {
      return priceAfterComa(String(money))
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    dateFormat(date) {
      return dateFormat(date);
    },
    formatDecWithoutComa(money) {
      return formatDecWithoutComa(money)
    }
  },
  computed: {},
};
</script>

<style>
.bulk-item-text {
  color: #263238;
  font-size: 14px;
  font-weight: 400;
  /* text-transform: capitalize; */
  /* text-overflow: ellipsis; */
  /* display: -webkit-box; */
  /* -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; */
}
.bulk-card {
  /* border: 1px solid black; */
  margin-top: 10px;
}
.bulk-text-footer {
  font-weight: 25px;
  color: #263238;
  margin-right: 10px;
  font-family: roboto;
  /* font-size: 18; */
}

/* @media (max-width: 375px) {
  .class-bulk {
    margin-left: -20px;
  }
}
@media (width: 768px) {
  .class-bulk {
    display: flex;
    justify-content: center;
  }
} */
</style>

<template>
  <v-container class="mt-14">
    <SidebarBulk />
    <div class="header-title">
      <v-icon left @click="$router.go(-1)">
        {{ iconArrowLeft }}
      </v-icon>
      <span >{{ $t("message.title-product-search") }}</span>
    </div>
    <div v-if="itemSearch.length == 0">
        <v-container>
          <div>
            <h3 style="color: grey" class="text-center">{{ $t("message.title-no-search-data") }}</h3>
          </div>
        </v-container>
      </div>
    <v-row>
      <v-col
        v-for="list in itemSearch"
        :key="list.id"
        cols="6"
        class=""
        lg="2"
        md="2"
        sm="2"
        active="1"
        style="padding-bottom: 10px; padding-right: 4px; padding-left: 4px;"
      >
        <ProductBulk :data="list" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SidebarBulk from "../../components/bulk/SidebarBulk.vue";
import ProductBulk from "../../components/bulk/ProductBulk.vue";
import { mdiArrowLeft } from "@mdi/js";

export default {
  name: "BulkSearch",
  components: {
    SidebarBulk,
    ProductBulk,
  },
  data() {
    return {
      iconArrowLeft: mdiArrowLeft,

    };
  },
  created() {
    // this.dispatchItemSearch()
  },
  computed: {
    itemSearch() {
      return this.$store.state.bulk_module.bulkSearchResult;
    },
  },
  methods: {
    dispatchItemSearch() {
      return this.$store.dispatch("bulk_module/bulkSearch");
    },
  },
};
</script>

<style>
.v-main__wrap {
  background-color: #ffffff;
  margin-top: 1px;
}
.item-class {
  background-color: transparent;
  min-width: 120px;
  min-height: 230px;
  margin-top: 24px;
  margin-left: 22px;
  word-wrap: break-word;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-top: -15px;
  margin-bottom: 10px;
  height: 25px;
}
</style>
